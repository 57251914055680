import {
  Box,
  Checkbox,
  FormControlLabel,
  Paper,
  TextField,
  Typography,
  Stack,
  ButtonBase,
  Switch,
  Skeleton,
  CircularProgress,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { capitalizeFirstLetter } from 'src/utils/helper';
import { Button, Modal } from 'src/components/shared';
import images from 'src/config/images';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RefreshIcon from '@mui/icons-material/Refresh';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import BrandVoiceForm from './BrandVoiceForm';
import { useSocketStore, withSocketStore } from '../../../../components/hooks/socketConnector';
import toast from '../../../../utils/toast';
import { useSelector } from 'react-redux';
import { StyledBox } from './styles';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: '0px',
  flexDirection: 'initial',
  textAlign: 'left',
  color: theme.palette.text.secondary,
  gridRow: '1',
  gridColumn: 'span 3',
  boxShadow:
    '0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)',
  marginBottom: '10px',
  borderRadius: 0,
}));

const menuItems = ['neutral', 'formal', 'informal'];
const checkBoxItems = [
  'empathetic',
  'friendly',
  'professional',
  'funny',
  'authoritative',
  'encouraging',
  'engaging',
  'persuasive',
  'anticipatory',
  'educational',
  'constructive',
  'diplomatic',
];

const CreateVoiceView = (props) => {
  const { emit, aiResponse: socketAiResponse } = useSocketStore();
  const { id } = useParams();

  const tenant = useSelector((state) => state.app.tenant);
  const [selectedItem, setSelectedItem] = useState('neutral');
  const history = useHistory();
  const [showSettings, setShowSettings] = React.useState(false);
  const [showBrandVoiceForm, setShowBrandVoiceForm] = React.useState(false);
  const [createdVoice, setCreatedVoice] = React.useState();
  const [aiResponse, setAiResponse] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [regenerateLoading, setRegenerateLoading] = React.useState(false);
  const [isRegenerate, setIsRegenerate] = React.useState(false);
  const [isDirectSave, setIsDirectSave] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [toneOfVoice, setToneOfVoice] = useState({
    name: '',
    sampleText: '',
    params: {
      style: 'neutral',
      tone: [],
    },
    formType: '',
  });

  const [errors, setErrors] = useState({
    name: '',
    sampleText: '',
    voicePrompt: false,
  });

  const [formState, setFormState] = useState({
    voicePrompt: '',
    isDefault: createdVoice?.isDefault,
    sampleText: '',
  });

  const handleSwitchChange = (event) => {
    const { checked } = event.target;
    setFormState({ ...formState, isDefault: checked });
  };

  useEffect(() => {
    setAiResponse(socketAiResponse?.body);
  }, [socketAiResponse]);

  const fetchVoiceData = async () => {
    setIsLoading(true);
    try {
      const existingVoice = await props.getVoiceById(id);
      // setToneOfVoice(existingVoice.voice);
      const formType =
        existingVoice?.voice?.params?.tone?.length > 0 || existingVoice?.voice?.sampleText === ''
          ? 'structured'
          : 'content';
      setToneOfVoice({
        name: existingVoice.voice.name,
        sampleText: existingVoice.voice.sampleText,
        params: existingVoice.voice.params,
        formType: formType,
      });
      setFormState({
        ...formState,
        isDefault: existingVoice.voice.isDefault,
        sampleText: existingVoice.voice.sampleText,
        voicePrompt: existingVoice.voice.voicePrompt,
      });
      setSelectedItem(existingVoice?.voice?.params?.style);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error('Error fetching voice data:', error);
    }
  };

  useEffect(() => {
    id && fetchVoiceData();
  }, [id]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setErrors((prevState) => ({
      ...prevState,
      [name]: '',
    }));
    setToneOfVoice((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    const updatedTones = checked
      ? [...toneOfVoice.params.tone, name]
      : toneOfVoice.params.tone.filter((tone) => tone !== name);
    setToneOfVoice((prevState) => ({
      ...prevState,
      params: {
        ...prevState.params,
        tone: updatedTones,
      },
    }));
  };

  const handleToggleSettings = async () => {
    let formErrors = { name: '' };
    let isValid = true;

    if (toneOfVoice.name.trim() === '') {
      formErrors.name = 'Voice Name is required';
      isValid = false;
    }
    setErrors(formErrors);
    if (isValid) {
      const formData = { ...toneOfVoice, formType: 'structured' };
      setToneOfVoice(formData);
      delete toneOfVoice?.formType;
      if (createdVoice?.id) {
        const updatedVoice = await props.updateVoice({
          id: id || createdVoice?.id,
          data: { ...toneOfVoice, sampleText: '' },
        });
        if (updatedVoice?.voice?.length > 0) {
          setCreatedVoice(updatedVoice?.voice[0]);
          setShowSettings((prevShowSettings) => !prevShowSettings);
        }
      } else {
        try {
          const createdNewVoice = await props.saveVoice({ ...toneOfVoice, sampleText: '' });
          setCreatedVoice(createdNewVoice?.voice);
          setShowSettings((prevShowSettings) => !prevShowSettings);
        } catch (e) {
          console.log(e);
        }
      }
    }
  };

  const handleItemClick = (item) => {
    setSelectedItem(item);
    setToneOfVoice((prevState) => ({
      ...prevState,
      params: {
        ...prevState.params,
        style: item,
      },
    }));
  };

  const validateForm = () => {
    let formErrors = { name: '', sampleText: '' };
    let isValid = true;

    if (toneOfVoice.name.trim() === '') {
      formErrors.name = 'Voice Name is required';
      isValid = false;
    }

    if (toneOfVoice.sampleText.trim() === '') {
      formErrors.sampleText = 'Example Content is required';
      isValid = false;
    }

    setErrors(formErrors);
    return isValid;
  };

  const handleSubmit = async () => {
    if (toneOfVoice?.formType === 'structured') return;
    if (validateForm()) {
      setLoading(true);
      setToneOfVoice({ ...toneOfVoice });
      try {
        if (id || createdVoice?.id) {
          const updatedVoice = await props.updateVoice({
            id: id || createdVoice?.id,
            data: toneOfVoice,
          });
          if (updatedVoice?.voice?.length > 0) {
            setCreatedVoice(updatedVoice?.voice[0]);
            setAiResponse(
              toneOfVoice?.formType === 'structured'
                ? ''
                : updatedVoice?.voice[0]?.voicePrompt || '',
            );
            setFormState({ ...formState, voicePrompt: updatedVoice?.voice[0]?.voicePrompt });
          }
          setShowBrandVoiceForm(true);
          setLoading(false);
          // if (id) {
          //   toast.success('Tone of voice updated successfully');
          // }
        } else {
          setRegenerateLoading(true);
          const createdNewVoice = await props.saveVoice(toneOfVoice);
          setCreatedVoice(createdNewVoice?.voice);
          if (tenant?.featureAccess && tenant?.featureAccess?.generateToneOfVoiceWithAI) {
            emit(
              'pipeline:execute',
              JSON.stringify({
                slug: 'create-tone-of-voice',
                payload: {
                  text: toneOfVoice?.sampleText,
                },
              }),
            );
            // toast.success('Tone of voice created successfully');
          }
          setLoading(false);
          setShowBrandVoiceForm(true);
          setTimeout(() => {
            setRegenerateLoading(false);
          }, 3000);
        }
      } catch (error) {
        setLoading(false);
        setErrors({ name: error.error.message });
        toast.error(
          id || createdVoice?.id ? 'Tone of voice update failed' : 'Tone of voice create failed',
        );
      }
    }
  };

  const regenerateAIResponse = async () => {
    if (!tenant?.featureAccess?.generateToneOfVoiceWithAI) return null;
    setRegenerateLoading(true);
    emit(
      'pipeline:execute',
      JSON.stringify({
        slug: 'create-tone-of-voice',
        payload: {
          text: toneOfVoice?.sampleText,
        },
      }),
    );
    setTimeout(() => {
      setRegenerateLoading(false);
    }, 3000);
    setIsDirectSave(true);
  };

  const handleSubmitData = async () => {
    const { formType, name, params, sampleText } = toneOfVoice || {};
    const { voicePrompt, isDefault } = formState || {};
    const isContentForm = formType === 'content';

    // Validation
    if (isContentForm && !voicePrompt) {
      setErrors((prevErrors) => ({ ...prevErrors, voicePrompt: true }));
      return;
    }

    // Create payload
    const payload = {
      id: createdVoice?.id || id,
      data: isContentForm
        ? { voicePrompt, isDefault: createdVoice?.isDefault }
        : { name, params, sampleText, isDefault },
    };

    // Handle success and error toasts
    const handleToast = (isSuccess) => {
      const action = props?.locationId ? 'updated' : 'created';
      if (isSuccess) toast.success(`Voice ${action} successfully`);
      else toast.error(`Voice ${action} failed`);
    };

    // Submit data
    try {
      await props.updateVoice(payload);
      handleToast(true);
      history.push('/profile/voice');
    } catch (error) {
      console.log(error);
      handleToast(false);
    }
  };

  const [content, setContent] = useState(toneOfVoice.sampleText);

  useEffect(() => {
    setContent(toneOfVoice.sampleText);
  }, [toneOfVoice]);

  return (
    <>
      <Box>
        <Box sx={{ pt: '12px', pb: '24px' }}>
          <ButtonBase
            sx={{ color: '#00000099', padding: 0 }}
            onClick={() => {
              if (isRegenerate) {
                setIsRegenerate(false);
                fetchVoiceData();
              } else {
                history.goBack();
              }
            }}
          >
            <Stack direction={'row'} alignItems={'center'} gap={'16px'}>
              <img src={images.icons.leftNavIcon} alt={''} />
              <Typography
                sx={{
                  color: 'rgba(0, 0, 0, 0.60)',
                  fontSize: '14px',
                  fontWeight: 500,
                  lineHeight: '24px',
                  letterSpacing: '0.4px',
                }}
              >
                Back
              </Typography>
            </Stack>
          </ButtonBase>
        </Box>
        <Item>
          <Typography fontSize="18px" fontWeight={500} color="#000000DE" padding="20px 24px">
            {id ? 'Edit tone of voice' : 'Create a new tone of voice'}
          </Typography>
          {isLoading ? (
              <Box display={'flex'} justifyContent={'center'} padding='30px'>
                <CircularProgress color="inherit" />
              </Box>
          ) : id && toneOfVoice?.formType === 'content' ? (
            <Box padding="30px">
              <Box>
                <Typography fontSize="14px" fontWeight={400} color="#000000DE">
                  Tone of voice name
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  padding="0px"
                  sx={{
                    mb: '20px',
                    '& .MuiInputBase-root': { height: '44px' },
                  }}
                  name="name"
                  value={toneOfVoice.name}
                  onChange={handleInputChange}
                  error={Boolean(errors.name)}
                  helperText={errors.name}
                />
              </Box>
              {isRegenerate ? (
                id ? (
                  formState?.sampleText && (
                    <Box>
                      <Typography fontSize="14px" fontWeight={400} color="#000000DE">
                        {content === '' ? 'Example' : 'Actual'} content
                      </Typography>
                      <Typography
                        fontSize="12px"
                        fontWeight={400}
                        color="#00000099"
                        padding="12px 0px "
                      >
                        Add content that reflects your desired tone of voice. We suggest 50-500
                        words.
                      </Typography>

                      <TextField
                        fullWidth
                        multiline
                        maxRows={7}
                        variant="outlined"
                        placeholder="Example content: blog article, social media posts, company mission, website copy, marketing emails, or any other content that matches your desired voice."
                        padding="0px"
                        sx={{
                          mb: '16px',
                          '& .MuiInputBase-root': {
                            height: '197px',
                            alignItems: 'flex-start',
                            textAlign: 'start',
                          },
                        }}
                        name="sampleText"
                        value={toneOfVoice.sampleText}
                        onChange={(e) => {
                          handleInputChange(e);
                          setContent(e.target.value);
                        }}
                        error={Boolean(errors.sampleText)}
                        helperText={errors.sampleText}
                      />
                    </Box>
                  )
                ) : (
                  <Box>
                    <Typography fontSize="14px" fontWeight={400} color="#000000DE">
                      {content === '' ? 'Example' : 'Actual'} content
                    </Typography>
                    <Typography
                      fontSize="12px"
                      fontWeight={400}
                      color="#00000099"
                      padding="12px 0px "
                    >
                      Add content that reflects your desired tone of voice. We suggest 50-500 words.
                    </Typography>

                    <TextField
                      fullWidth
                      multiline
                      maxRows={7}
                      variant="outlined"
                      placeholder="Example content: blog article, social media posts, company mission, website copy, marketing emails, or any other content that matches your desired voice."
                      padding="0px"
                      sx={{
                        mb: '16px',
                        '& .MuiInputBase-root': {
                          height: '197px',
                          alignItems: 'flex-start',
                          textAlign: 'start',
                        },
                      }}
                      name="sampleText"
                      value={toneOfVoice.sampleText}
                      onChange={(e) => {
                        handleInputChange(e);
                        setContent(e.target.value);
                      }}
                      error={Boolean(errors.sampleText)}
                      helperText={errors.sampleText}
                    />
                  </Box>
                )
              ) : (
                <Box>
                  <Typography fontSize="14px" fontWeight={400} color="#000000DE">
                    Your tone of voice
                  </Typography>

                  <TextField
                    fullWidth
                    multiline
                    maxRows={7}
                    variant="outlined"
                    placeholder="This is output"
                    padding="0px"
                    sx={{
                      mb: '16px',
                      '& .MuiInputBase-root': {
                        height: '197px',
                        alignItems: 'flex-start',
                        textAlign: 'start',
                      },
                    }}
                    name="voicePrompt"
                    value={formState?.voicePrompt}
                    onChange={(e) => {
                      const value = e.target.value;
                      setFormState({ ...formState, voicePrompt: value });
                    }}
                    error={errors.voicePrompt}
                    helperText={errors.voicePrompt ? 'This field is required' : ''}
                  />
                </Box>
              )}

              <Box display="flex">
                {!isRegenerate &&
                  tenant?.featureAccess &&
                  tenant?.featureAccess?.generateToneOfVoiceWithAI && (
                    <Button
                      variant="text"
                      onClick={() => {
                        setToneOfVoice({ ...toneOfVoice, name: '', sampleText: '' });
                        setIsRegenerate(true);
                      }}
                      disableElevation
                      height="44px"
                      sx={{
                        background: 'transparent',
                        color: '#1976D2',
                        padding: '10px 24px 10px 0px',
                        marginRight: '16px',
                        borderRadius: '3px',
                        textTransform: 'none',
                        '&:hover': {
                          background: '#1976D230',
                        },
                      }}
                    >
                      <Typography
                        fontSize="16px"
                        fontWeight="500"
                        lineHeight="24px"
                        variant="body"
                        display="flex"
                      >
                        <RefreshIcon /> <div>Regenerate</div>
                      </Typography>
                    </Button>
                  )}
              </Box>
              <Box display="flex" justifyContent="end">
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={isRegenerate ? handleSubmit : handleSubmitData}
                  disableElevation
                  endIcon={isDirectSave ? null : <ArrowForwardIcon />}
                  height="44px"
                  sx={{
                    padding: '10px 24px',
                    borderRadius: '3px',
                    textTransform: 'none',
                  }}
                  loading={props?.loading}
                >
                  <Typography fontSize="16px" fontWeight="500" lineHeight="24px" variant="body">
                    {isRegenerate ? 'Regenerate your tone of voice' : 'Save'}
                  </Typography>
                </Button>
              </Box>
            </Box>
          ) : (
            <Box padding="30px">
              <Box>
                <Typography fontSize="14px" fontWeight={400} color="#000000DE">
                  Tone of voice name
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  padding="0px"
                  sx={{
                    mb: '20px',
                    '& .MuiInputBase-root': { height: '44px' },
                  }}
                  name="name"
                  value={toneOfVoice.name}
                  onChange={handleInputChange}
                  error={Boolean(errors.name)}
                  helperText={errors.name}
                />
              </Box>
              {id ? (
                toneOfVoice?.formType === 'content' && (
                  <Box>
                    <Typography fontSize="14px" fontWeight={400} color="#000000DE">
                      {content === '' ? 'Example' : 'Actual'} content
                    </Typography>
                    <Typography
                      fontSize="12px"
                      fontWeight={400}
                      color="#00000099"
                      padding="12px 0px "
                    >
                      Add content that reflects your desired tone of voice. We suggest 50-500 words.
                    </Typography>

                    <TextField
                      fullWidth
                      multiline
                      maxRows={7}
                      variant="outlined"
                      placeholder="Example content: blog article, social media posts, company mission, website copy, marketing emails, or any other content that matches your desired voice."
                      padding="0px"
                      sx={{
                        mb: '16px',
                        '& .MuiInputBase-root': {
                          height: '197px',
                          alignItems: 'flex-start',
                          textAlign: 'start',
                        },
                      }}
                      name="sampleText"
                      value={toneOfVoice.sampleText}
                      onChange={(e) => {
                        handleInputChange(e);
                        setContent(e.target.value);
                      }}
                      error={Boolean(errors.sampleText)}
                      helperText={errors.sampleText}
                    />
                  </Box>
                )
              ) : (
                <Box>
                  <Typography fontSize="14px" fontWeight={400} color="#000000DE">
                    {content === '' ? 'Example' : 'Actual'} content
                  </Typography>
                  <Typography
                    fontSize="12px"
                    fontWeight={400}
                    color="#00000099"
                    padding="12px 0px "
                  >
                    Add content that reflects your desired tone of voice. We suggest 50-500 words.
                  </Typography>

                  <TextField
                    fullWidth
                    multiline
                    maxRows={7}
                    variant="outlined"
                    placeholder="Example content: blog article, social media posts, company mission, website copy, marketing emails, or any other content that matches your desired voice."
                    padding="0px"
                    sx={{
                      mb: '16px',
                      '& .MuiInputBase-root': {
                        height: '197px',
                        alignItems: 'flex-start',
                        textAlign: 'start',
                      },
                    }}
                    name="sampleText"
                    value={toneOfVoice.sampleText}
                    onChange={(e) => {
                      handleInputChange(e);
                      setContent(e.target.value);
                    }}
                    error={Boolean(errors.sampleText)}
                    helperText={errors.sampleText}
                  />
                </Box>
              )}
              {!id ? (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <AddCircleOutlineIcon style={{ color: '#1976D2' }} />
                  <Typography
                    fontSize="14px"
                    fontWeight={500}
                    color="#1976D2"
                    sx={{ cursor: 'pointer' }}
                    onClick={handleToggleSettings}
                  >
                    Add a structured tone of voice
                  </Typography>
                </Box>
              ) : toneOfVoice?.formType === 'structured' ? (
                <Box>
                  <Box sx={{ padding: '0px 16px' }}>
                    <Box>
                      <Typography
                        fontSize="14px"
                        fontWeight={400}
                        marginBottom="8px"
                        color="#00000099"
                      >
                        Select style (Optional)
                      </Typography>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          border: '1px solid #0000001F',
                          borderRadius: '7px',
                          width: 'fit-content',
                        }}
                      >
                        {menuItems.map((item, index) => (
                          <div
                            key={index}
                            style={
                              item === selectedItem
                                ? {
                                    cursor: 'pointer',
                                    color: '#1976D2',
                                    backgroundColor: '#1976D214',
                                    margin: '4px',
                                    padding: '8px 16px',
                                    borderRadius: '5px',
                                    width: '156px',
                                    textAlign: 'center',
                                    fontWeight: '500',
                                  }
                                : {
                                    margin: '4px',
                                    fontWeight: '400',
                                    textAlign: 'center',
                                    width: '156px',
                                    color: '#00000099',
                                    cursor: 'pointer',
                                    backgroundColor: '#ffffff',
                                    padding: '8px 16px',
                                    borderRadius: '4px',
                                  }
                            }
                            onClick={() => handleItemClick(item)}
                          >
                            {capitalizeFirstLetter(item)}
                          </div>
                        ))}
                      </Box>
                    </Box>
                    <Box mt={'24px'}>
                      <Typography
                        fontSize="14px"
                        fontWeight={400}
                        marginBottom="8px"
                        color="#00000099"
                      >
                        Select tones (Optional)
                      </Typography>
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                        {checkBoxItems.map((label, index) => (
                          <FormControlLabel
                            key={index}
                            control={
                              <Checkbox
                                checked={toneOfVoice.params.tone.includes(label)}
                                onChange={handleCheckboxChange}
                                name={label}
                              />
                            }
                            label={
                              <Typography fontSize="14px" color={'#666'}>
                                {capitalizeFirstLetter(label)}
                              </Typography>
                            }
                            sx={{ flex: '1 1 calc(100% / 7)', maxWidth: '115px' }}
                          />
                        ))}
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    overflow="hidden"
                    padding="24px 16px 0px 16px"
                  >
                    <Box display="flex" alignItems="center" flex="1">
                      {/* <Switch /> */}
                      <FormControlLabel
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          margin: 0,
                        }}
                        control={
                          <Switch
                            checked={formState.isDefault}
                            onChange={handleSwitchChange}
                            name="status"
                            color="secondary"
                          />
                        }
                        label=""
                      />
                      <Typography
                        fontSize="16px"
                        fontWeight="400"
                        lineHeight="24px"
                        variant="body"
                        color={'#000000DE'}
                      >
                        Save as default voice
                      </Typography>
                    </Box>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={
                        toneOfVoice?.formType === 'structured' ? handleSubmitData : handleSubmit
                      }
                      disableElevation
                      height="44px"
                      sx={{
                        padding: '10px 24px',
                        borderRadius: '3px',
                        textTransform: 'none',
                      }}
                      loading={loading}
                    >
                      <Typography fontSize="16px" fontWeight="500" lineHeight="24px" variant="body">
                        Save
                      </Typography>
                    </Button>
                  </Box>
                </Box>
              ) : null}

              <Modal
                open={showSettings === true}
                title={'Add a structured tone of voice'}
                sx={{
                  '& .MuiDialog-paper': {
                    borderRadius: '10px',
                    maxWidth: '672px',
                  },
                  '& .MuiDialogTitle-root': {
                    boxSizing: 'border-box',
                    padding: '24px',
                    '& > div': {
                      alignItems: 'baseline',
                    },
                  },
                  '& .MuiDialogActions-root': {
                    padding: '0px !important',
                  },
                  '& .MuiDialogContent-root': {
                    padding: '0px 24px 24px   24px',
                  },
                }}
                titleStyle={{
                  color: '#000000DE',
                  fontSize: '20px',
                  fontWeight: 600,
                  lineHeight: '23.34px',
                }}
                onClose={() => {
                  setShowSettings(false);
                }}
              >
                <Box sx={{ height: '400px', padding: '0px 16px' }}>
                  <Box>
                    <Typography
                      fontSize="14px"
                      fontWeight={400}
                      marginBottom="8px"
                      color="#00000099"
                    >
                      Select style (Optional)
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        border: '1px solid #0000001F',
                        borderRadius: '7px',
                        width: 'fit-content',
                      }}
                    >
                      {menuItems.map((item, index) => (
                        <div
                          key={index}
                          style={
                            item === selectedItem
                              ? {
                                  cursor: 'pointer',
                                  color: '#1976D2',
                                  backgroundColor: '#1976D214',
                                  margin: '4px',
                                  padding: '8px 16px',
                                  borderRadius: '5px',
                                  width: '156px',
                                  textAlign: 'center',
                                  fontWeight: '500',
                                }
                              : {
                                  margin: '4px',
                                  fontWeight: '400',
                                  textAlign: 'center',
                                  width: '156px',
                                  color: '#00000099',
                                  cursor: 'pointer',
                                  backgroundColor: '#ffffff',
                                  padding: '8px 16px',
                                  borderRadius: '4px',
                                }
                          }
                          onClick={() => handleItemClick(item)}
                        >
                          {capitalizeFirstLetter(item)}
                        </div>
                      ))}
                    </Box>
                  </Box>
                  <Box mt={'24px'}>
                    <Typography
                      fontSize="14px"
                      fontWeight={400}
                      marginBottom="8px"
                      color="#00000099"
                    >
                      Select tones (Optional)
                    </Typography>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                      {checkBoxItems.map((label, index) => (
                        <FormControlLabel
                          key={index}
                          control={
                            <Checkbox
                              checked={toneOfVoice.params.tone.includes(label)}
                              onChange={handleCheckboxChange}
                              name={label}
                            />
                          }
                          label={
                            <Typography fontSize="14px" color={'#666'}>
                              {capitalizeFirstLetter(label)}
                            </Typography>
                          }
                          sx={{ flex: '1 1 calc(100% / 7)', maxWidth: '115px' }}
                        />
                      ))}
                    </Box>
                  </Box>
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  overflow="hidden"
                  padding="24px 16px 0px 16px"
                >
                  <Box display="flex" alignItems="center" flex="1">
                    {/* <Switch /> */}
                    <FormControlLabel
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        margin: 0,
                      }}
                      control={
                        <Switch
                          checked={formState.isDefault}
                          onChange={handleSwitchChange}
                          name="status"
                          color="secondary"
                        />
                      }
                      label=""
                    />
                    <Typography
                      fontSize="16px"
                      fontWeight="400"
                      lineHeight="24px"
                      variant="body"
                      color={'#000000DE'}
                    >
                      Save as default voice
                    </Typography>
                  </Box>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleSubmitData}
                    disableElevation
                    height="44px"
                    sx={{
                      padding: '10px 24px',
                      borderRadius: '3px',
                      textTransform: 'none',
                    }}
                    loading={loading}
                  >
                    <Typography fontSize="16px" fontWeight="500" lineHeight="24px" variant="body">
                      Save
                    </Typography>
                  </Button>
                </Box>
              </Modal>

              {!id && (
                <Box display="flex" justifyContent="end" mt="20px">
                  <Button
                    variant="contained"
                    color="secondary"
                    endIcon={<ArrowForwardIcon />}
                    onClick={handleSubmit}
                    disableElevation
                    sx={{
                      padding: '10px 16px',
                    }}
                    loading={loading}
                  >
                    <Typography fontSize="16px" fontWeight="500" lineHeight="24px" variant="body">
                      Create tone of voice
                    </Typography>
                  </Button>
                </Box>
              )}
            </Box>
          )}
        </Item>
      </Box>
      <Modal
        open={showBrandVoiceForm === true}
        title={'Your tone of voice'}
        sx={{
          '& .MuiDialog-paper': {
            borderRadius: '10px',
            maxWidth: '720px',
          },
          '& .MuiDialogTitle-root': {
            boxSizing: 'border-box',
            padding: '24px',
            '& > div': {
              alignItems: 'baseline',
            },
          },
          '& .MuiDialogActions-root': {
            padding: '0px !important',
          },
        }}
        titleStyle={{
          color: '#000000DE',
          fontSize: '20px',
          fontWeight: 600,
          lineHeight: '23.34px',
        }}
        onClose={() => {
          setShowBrandVoiceForm(false);
        }}
      >
        <BrandVoiceForm
          regenerateLoading={regenerateLoading}
          updateVoice={props?.updateVoice}
          regenerateAIResponse={regenerateAIResponse}
          aiResponse={aiResponse}
          locationId={id}
          createdVoice={createdVoice}
          setIsRegenerate={setIsRegenerate}
        />
      </Modal>
    </>
  );
};

export default withSocketStore(CreateVoiceView);
