import React, { createContext, useEffect, useState, useContext, useCallback } from 'react';
import io from 'socket.io-client';
import toast from 'src/utils/toast';
import cookieUtils from 'src/utils/cookieUtils';
import { useSelector } from 'react-redux';

const SocketContext = createContext();

export const useSocketStore = () => {
  const { socket, events, isConnected, emit, disconnect, setEvents, aiResponse, setAiResponse } =
    useContext(SocketContext);
  return {
    aiResponse,
    socket,
    events,
    isConnected,
    emit,
    disconnect,
    setEvents,
    setAiResponse,
  };
};

export const SocketProvider = ({ children }) => {
  const user = useSelector((state) => state.auth.user);
  const [socket, setSocket] = useState(null);
  const [events, setEvents] = useState([]);
  const [isConnected, setIsConnected] = useState(false);
  const [aiResponse, setAiResponse] = useState(null);
  const aiToken = cookieUtils.get('AIBRAIN_TOKEN') || user?.aiServiceToken;
  console.log('events',events)
  useEffect(() => {
    initSocket();
  }, [aiToken]);

  const initSocket = useCallback(() => {
    if (aiToken) {
      const socketClient = io(`${process.env.REACT_APP_BASE_SOCKET_URL}`, {
        withCredentials: true,
        transports: ['websocket'],
        query: {
          token: aiToken || '',
        },
      });

      setSocket(socketClient);
    }
  }, [aiToken, user]);

  useEffect(() => {
    if (socket) {
      socket.onAny((event, payload) => {
        if (event === 'user:login:success') {
          localStorage.setItem('token', payload.token);
          setTimeout(() => {
            socket.disconnect();
            initSocket();
          }, 100);
        }

        if (event === 'pipeline:execute:success') {
          setAiResponse({
            body: payload?.json?.description || payload?.json?.body || '',
            subject: payload?.json?.subject || '',
            payload: payload,
          });
        }

        if (event === 'pipeline:execute:error') {
          toast.error(payload?.message || 'AI generation failed');
        }

        if (event === 'oauth:google:url') {
          window.location.href = payload.url;
          // localStorage.setItem("oauth-google-token", payload.token);
          // location.push("/");
        }
        setEvents((prevEvents) => [
          {
            message: `⬇️ Received - ${event}`,
            time: new Date(),
            event: event,
            payload: JSON.stringify(payload),
          },
          ...prevEvents,
        ]);
      });

      socket.on('connect', () => {
        setIsConnected(true);
        setEvents((prevEvents) => [
          {
            message: '🚀 Connected to server',
            time: new Date(),
            payload: `${process.env.REACT_APP_BASE_SOCKET_URL}`,
          },
          ...prevEvents,
        ]);
      });

      socket.on('disconnect', () => {
        setIsConnected(false);
        setEvents((prevEvents) => [
          { message: '🔌 Disconnected!', time: new Date() },
          ...prevEvents,
        ]);
      });
    }
  }, [socket]);

  const emit = (event, payload) => {
    setAiResponse(null);
    socket.emit(event, payload);
    setEvents((prevEvents) => [
      { message: `⬆️ Sent - ${event}`, payload, time: new Date(), event },
      ...prevEvents,
    ]);
  };

  const disconnect = () => {
    socket.disconnect();
    setIsConnected(false);
  };

  return (
    <SocketContext.Provider
      value={{
        events,
        socket,
        isConnected,
        emit,
        disconnect,
        setEvents,
        aiResponse,
        setAiResponse,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};

export const withSocketStore = (Component) => (props) =>
  (
    <SocketProvider>
      <Component {...props} />
    </SocketProvider>
  );
