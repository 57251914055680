import React, { useState, useEffect } from 'react';
import {Link, useHistory} from 'react-router-dom';

import { Tooltip, Typography, Box, FormControlLabel, Switch } from '@mui/material';
import { Button, Form } from 'src/components/shared';

import UserGridActions from './VoiceGridActions';
import { capitalizeFirstLetter } from 'src/utils/helper';
import VisibilityIcon from '@mui/icons-material/Visibility';

import moment from 'moment';
import { dateTimeFormat } from 'src/config';
import toast from '../../../../utils/toast';

export function voiceColumns(data, drawerToggle, props) {
  const [columns, setColumns] = useState([]);
  const history = useHistory();

  const handleVoiceStatusChange = async (voiceId, status) => {
    try {
      const rowData = { id: voiceId, status: status };
      const res = await props.updateVoiceStatus(rowData);
      if (res?.rowData) {
        const status = res?.rowData?.status === 'active' ? 'enable' : 'disable';
        toast.success(`Voice ${status} successfully`);
      }
    } catch (error) {}
  };
  useEffect(() => {
    setColumns([
      {
        label: 'Status',
        name: 'status',
        options: {
          customHeadRender: () => null,
          filter: true,
          sort: false,
          draggable: true,
          setCellProps: () => ({
            style: { minWidth: 70, width: 40, maxWidth: 40, padding: 0 },
          }),
          customBodyRenderLite: (dataIndex) => {
            const rData = data[dataIndex];
            const [switchChecked, setSwitchChecked] = useState(rData?.status === 'active');

            useEffect(() => {
              setSwitchChecked(rData?.status === 'active');
            }, [rData]);

            return (
              <Box
                ml={1}
                ml={1}
                sx={{
                  '& .Mui-checked+.MuiSwitch-track': {
                    backgroundColor: 'rgba(2, 136, 209, 0.7) !important',
                  },
                  '& .MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked': {
                    backgroundColor: '#0288D1 !important',
                  },
                }}
              >
                <FormControlLabel
                  sx={{
                    display: 'block',
                  }}
                  control={
                    <Switch
                      checked={switchChecked}
                      onChange={(event) => {
                        const value = event.target.checked;
                        if(!rData?.isDefault){
                          handleVoiceStatusChange(rData.id, value ? 'active' : 'inactive');
                          setSwitchChecked(event.target.checked);
                        }
                      }}
                      name="status"
                      color="secondary"
                    />
                  }
                  label=""
                />
              </Box>
            );
          },
        },
      },
      {
        label: 'Name',
        name: 'name',
        options: {
          customHeadRender: () => null,
          filter: true,
          sort: true,
          draggable: true,
          setCellProps: () => ({
            style: { width: 200 },
          }),
          customBodyRenderLite: (dataIndex) => {
            const rData = data[dataIndex];
            return (
              <Box onClick={()=> history.push(`/voice/update/` + rData?.id)} sx={{ cursor: "pointer"}}>
                <Typography
                  variant="body1"
                  color="textPrimary"
                  sx={{ fontSize: '20px', fontWeight: '600' }}
                >
                  {capitalizeFirstLetter(rData?.name)}
                </Typography>
              </Box>
            );
          },
        },
      },
      {
        label: 'Description',
        name: 'description',
        options: {
          customHeadRender: () => null,
          filter: true,
          sort: false,
          draggable: true,
          setCellProps: () => ({
            style: { minWidth: 80, minHeight: 109 },
          }),
          customBodyRenderLite: (dataIndex) => {
            const rData = data[dataIndex];
            const truncatedDescription = rData?.voicePrompt
              ? `${capitalizeFirstLetter(rData.voicePrompt).slice(0, 200)}${
                  rData.voicePrompt.length > 200 ? '...' : ''
                }`
              : '-';

            return (
              <Typography
                color="textPrimary"
                variant="body2"
                sx={{
                  letter: '0.4px',
                  color: '#00000099',
                  maxWidth: '360px',
                  minHeight: '120px',
                  display: 'flex',
                  alignItems: 'center',
                  fontSize: '12px',
                  fontWeight: '400',
                  lineHeight: '20px',
                }}
              >
                {truncatedDescription}
              </Typography>
            );
          },
        },
      },
      {
        label: 'isDefault',
        name: 'isDefault',
        options: {
          customHeadRender: () => null,
          filter: true,
          sort: false,
          draggable: true,
          setCellProps: () => ({
            style: { width: 330 },
          }),
          customBodyRenderLite: (dataIndex) => {
            const rData = data[dataIndex];
            return (
              <>
                {rData?.isDefault ? (
                  <Box>
                    <Typography
                      style={{
                        fontSize: '16px',
                        fontWeight: 400,
                        color: '#1976D2',
                        height: '42px',
                        padding: '0px 16px',
                        borderRadius: '50px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: '#1976D217',
                        width: 'fit-content',
                      }}
                    >
                      Default
                    </Typography>
                  </Box>
                ) : (
                  ''
                )}
              </>
            );
          },
        },
      },
      {
        name: 'id',
        label: ' ',
        options: {
          customHeadRender: () => null,
          filter: false,
          sort: false,
          draggable: false,
          allowToggle: false,
          viewColumns: false,
          setCellProps: () => ({
            style: { minWidth: 15, width: 35, maxWidth: 35, padding: 0 },
          }),
          customBodyRender: (value) => {
            const row = data.find((user) => user.id === value);
            return (
              <UserGridActions
                drawerToggle={drawerToggle}
                // status={row?.status}
                row={row}
                {...props}
              />
            );
          },
        },
      },
    ]);
  }, [data]);

  return columns;
}
